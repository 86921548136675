import { node, shape } from 'prop-types'
import Typography from '../../atoms/typography'
import Button from '../button'
import Card from './card'
import { CardStyled, Description, Subtitle, Title } from './card-text.styled'
import ConditionalWrapper from '@bluheadless/ui/src/particles/conditional-wrapper'
import Link from '@bluheadless/ui/src/molecules/link'

const CardText = ({
	title,
	titleProps,
	subtitle,
	subtitleProps,
	description,
	descriptionProps,
	cta1,
	cta1Props,
	cta2,
	cta2Props,
	wrapContentWithLink,
	...props
}) => {
	return (
		<CardStyled
			{...props}
			content={
				<ConditionalWrapper
					condition={wrapContentWithLink && !!cta1Props?.href}
					wrapper={(children) => (
						<Link href={cta1Props?.href} underline="none">
							{children}
						</Link>
					)}
				>
					{title && (
						<Title variant="h4" {...titleProps}>
							{title}
						</Title>
					)}
					{subtitle && (
						<Subtitle variant="subtitle2" color="grey.medium" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					{description && (
						<Description variant="body1" component="div" color="grey.medium" mt={3} {...descriptionProps}>
							{description}
						</Description>
					)}
				</ConditionalWrapper>
			}
			actions={
				cta1 || cta2 ? (
					<>
						{cta1 && (
							<Button variant="text" {...cta1Props}>
								{cta1}
							</Button>
						)}
						{cta2 && (
							<Button variant="text" {...cta2Props}>
								{cta2}
							</Button>
						)}
					</>
				) : null
			}
			href={cta1Props?.href}
		/>
	)
}

CardText.defaultProps = {
	...Card.defaultProps,
	elevation: 0,
	variant: 'outlined',
}

CardText.propTypes = {
	...Card.propTypes,
	title: node,
	titleProps: shape(Typography.propTypes),
	subtitle: node,
	subtitleProps: shape(Typography.propTypes),
	description: node,
	descriptionProps: shape(Typography.propTypes),
	cta1: node,
	cta1Props: shape(Button.propTypes),
	cta2: node,
	cta2Props: shape(Button.propTypes),
}

export default CardText
