import { Subtitle, Title } from '@bluheadless/ui/src/molecules/card/card-text.styled'
import { CardContentStyled } from '@bluheadless/ui/src/molecules/card/card.styled'
import { styled } from '@mui/system'
import CardText from './card-text'

export const CardTextStyled = styled(CardText)`
	position: relative;
	border-radius: calc(var(--card-border-radius) * 3);
	${CardContentStyled} {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% - ${({ theme }) => theme.spacing(2)});
		padding: ${({ theme }) => theme.spacing('22px', 1, 2)};
		text-align: center;
	}
	${Title} {
		text-transform: uppercase;
		margin-bottom: ${({ theme }) => theme.spacing(1)};
	}
	${Subtitle} {
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		line-height: ${({ theme }) => theme.typography.pxToRem(17)};
	}
`
