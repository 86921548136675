import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { cx } from '@emotion/css'
import CardImage from '../../../../../../molecules/card/card-image'
import CardText from '../../../../../../molecules/card/card-text'

const BannerCard = ({
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	subtitle,
	subtitleTag,
	subtitleColor,
	subtitleColorMobile,
	ctas,
	image,
	content,
	content2,
	sx,
	cssProp,
	className,
	minHeight,
	RichContentComponent,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
}) => {
	const links = useLinks([
		{ type: ctas[0]?.linkType, href: ctas[0]?.link },
		{ type: ctas[1]?.linkType, href: ctas[1]?.link },
	])

	const hasImage = !!image?.mobile?.src
	const CardComponent = hasImage ? CardImage : CardText

	const { promotionClick } = useTrackingsActions()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	const getCtaProps = ({ openInNewTab, variant, color }, link) => {
		return {
			target: openInNewTab ? '_blank' : '_self',
			variant,
			color,
			href: link,
			onClick: (e) => {
				if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
					trackClickEvent({
						event: e,
						id: seoBannerId,
						name: seoBannerName,
						creative: seoBannerCreative,
						position: seoBannerPosition,
					})
				}
			},
		}
	}

	return (
		<CardComponent
			sx={{ ...sx, minHeight }}
			css={cssProp}
			className={cx('Magento-PageBuilder-Banner-Card', className)}
			{...(hasImage
				? {
						image: {
							alt: title,
							src: image.mobile.src,
							originalWidth: image.mobile.originalWidth,
							originalHeight: image.mobile.originalHeight,
						},
				  }
				: {})}
			title={title}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			subtitle={subtitle}
			subtitleProps={{ component: subtitleTag, sx: { color: { xs: subtitleColorMobile, md: subtitleColor } } }}
			descriptionProps={{ component: 'div' }}
			description={
				(content || content2) && RichContentComponent ? (
					<>
						{content && RichContentComponent && <RichContentComponent content={content} />}
						{content2 && RichContentComponent && <RichContentComponent content={content2} />}
					</>
				) : null
			}
			cta1={ctas[0]?.text}
			cta1Props={ctas[0] ? getCtaProps(ctas[0], links[0]) : {}}
			cta2={ctas[1]?.text}
			cta2Props={ctas[1] ? getCtaProps(ctas[1], links[1]) : {}}
			wrapContentWithLink={!!hasImage}
		/>
	)
}

export default BannerCard
